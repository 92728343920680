import styled from 'styled-components';
import Background from './images/loginbg.svg';

export default styled.div`
  background-image: url(${Background});
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  align-items: center;
  .login-container {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 1rem;
    .form-container {
      width: 300px;
      form {
        display: flex;
        flex-direction: column;
        .username-input, .password-input {
          padding: .8rem .5rem;
          border: 1px solid #8C8994;
          border-radius: 10px;
          margin-top: 1rem;
        }
        .remember-forgot {
          display: flex;
          justify-content: space-between;
          margin-top: 1rem;
          .checkbox-input {
            .check-box {
              margin-right: .5rem;
              accent-color: #6CB132;
            }
          }
          .forgot-password {

          }
        }
        .submit-button {
          background: #6CB132;
          text-align: center;
          margin-left: 0;
          margin-top: 1rem;
          width: 100%;
          &:disabled {
            background: #6CB13288;
          }
        }
      }
    }
    .eula {
      margin: 0 auto;
      font-size: .9rem;
      color: #6CB132;
      display: flex;
      justify-content: center;
    }
    .horizontal-br {
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      hr {
        width: 45%;
        height: 1%;
      }
    }
    .oauth-buttons {
      width: 100%;
      margin-left: 0;
    }
    .redirection {
      display: flex;
      justify-content: center;
      color: #F1A73C;
      span {
        font-weight: bold;
        margin-left: .4rem;
      }
    }
  }
`