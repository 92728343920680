import React from "react";
import RightSidebarStyles from "./styles";

import SidebarProfile from "../SidebarProfile";
import Button from "../Button";
import {
  helpIcon,
  passwordIcon,
  restaurantIcon,
  savedDishesIcon,
} from "../../constants/rightMenuButtons";
import { useNavigate } from "react-router-dom";
import { useFetchUserQuery } from "../../store";
import Spinner from "../Spinner";

const RightSidebar = () => {
  const navigate = useNavigate();
  const { data, error, isFetching } = useFetchUserQuery();

  const user = data?.user;
  let sidebarProfile;

  if (isFetching) {
    sidebarProfile = <Spinner />;
  } else if (error) {
    sidebarProfile = <div>Something went wrong</div>;
  } else {
    sidebarProfile = (
      <SidebarProfile
        profileImage={user?.profilePic}
        name={user?.username}
        email={user?.email}
        location="Los Santos"
      />
    );
  }

  const routeOnClick = () => {
    navigate("/my-restaurants");
  };
  return (
    <RightSidebarStyles>
      {sidebarProfile}
      <div className="sidebar-menu">
        <Button
          label="My restaurant"
          leftIcon={restaurantIcon}
          style={{
            width: "90%",
            fontWeight: 600,
          }}
          className={`${
            window.location.pathname === "/my-restaurants" ? "active" : ""
          }`}
          onClick={routeOnClick}
        />
        <Button
          label="Saved Dishes"
          leftIcon={savedDishesIcon}
          style={{
            width: "90%",
            fontWeight: 600,
          }}
        />
        <Button
          label="Update Password"
          leftIcon={passwordIcon}
          style={{
            width: "90%",
            fontWeight: 600,
          }}
        />
        <Button
          label="Help"
          leftIcon={helpIcon}
          style={{
            width: "90%",
            fontWeight: 600,
          }}
        />
      </div>
    </RightSidebarStyles>
  );
};

export default RightSidebar;
