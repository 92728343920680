import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { URLs } from "../../constants/baseUrls";
import { setUser } from "../slices/userSlice";

const BASE_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PROD_URL
    : URLs.DEV_URL;

export const postApi = createApi({
  reducerPath: "post",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/post`,
  }),
  endpoints: (builder) => ({
    fetchPosts: builder.query({
      query: (data) => {
        return {
          url: "getAll",
          method: "GET",
          params: {
            pageNo: data?.pageNo ? data?.pageNo : 1,
            size: data?.size ? data?.size : 10
          }
        };
      },
    }),
    fetchPostById: builder.query({
      query: (data) => {
        return {
          url: 'post/get/',
          method: "GET",
          body: data,
          param: {
            postId: data.postId
          }
        }
      },
      transformResponse: (result) => 
        result,
        async onQueryStarted(data, {dispatch, queryFulfilled}) {
          try {
            const data = await queryFulfilled;
            const { user } = data.data;
            console.log('ResponseTransform', user);
            dispatch(setUser(user))
          } catch(error) {}
        }
    })
  }),
});

export const {
  useFetchPostsQuery,
  useFetchPostByIdQuery
} = postApi;