import React from 'react';
import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';

const DonutChart = ({data, options}) => {
  data = {
    datasets: [
      {
        data: [40, 60],
        backgroundColor: ['#6CB13233', '#6CB132'],
        hoverBackgroundColor: ['#6CB13233', '#6CB132'],
      },
    ],
  };

  return <Doughnut data={data} options={options} />;
};

export default DonutChart;
