import styled from 'styled-components';

export default styled.div`
	width: 20%;
	box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
	height: 100vh;
	position: sticky;
	background: white;
	left: 0;
	top: 0;
	.image {
		img {
			height: 96px;
		}
	}
	.menu-items {
		li {
			display: flex;
			align-items: center;
			justify-content: center;
			a {
				display: flex;
				align-items: center;
				font-weight: 600;
				padding: 1rem 0 1rem;
				width: 70%;
				span {
					color: #8c8994;
					&:nth-child(2) {
						margin-left: 1rem;
						font-size: 1.2rem;
					}
					&.active {
						color: #f1a73c;
					}
				}
				&:hover {
					span {
						color: #f1a73c;
						svg: {
							color: #f1a73c;
						}
					}
				}
			}
		}
	}
`;
