import React, { useEffect, useState } from "react";

import LoginPageStyles from "./styles";
import Button from "../../components/Button";

import { BsFacebook } from "react-icons/bs";
import { FcGoogle } from "react-icons/fc";
import { BsApple } from "react-icons/bs";
import { useLoginUserMutation } from "../../store";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const [loginUser, { isLoading, isError, error, isSuccess }] =
    useLoginUserMutation();
  const [loginData, setloginData] = useState({
    username: null,
    password: null,
  });
  const navigate = useNavigate();

  const handleLoginOnChange = (event) => {
    if (event.target.name === "username") {
      setloginData({ ...loginData, username: event.target.value });
    }
    if (event.target.name === "password") {
      setloginData({ ...loginData, password: event.target.value });
    }
  };

  const loginOnSubmit = (event) => {
    event.preventDefault();
    loginUser(loginData);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success("Logged in Successfully");
      navigate('/');
    }
    if (isError) {
      if (Array.isArray(error.data.error)) {
        error.data.error.forEach((el) =>
          toast.error(el.message, {
            position: "top-right",
          })
        );
      } else {
        toast.error(error.data.message, {
          position: "top-right",
        });
      }
    }
  }, [isLoading, error, isError, isSuccess]);

  return (
    <LoginPageStyles>
      <div className="login-container">
        <h3>Log in</h3>
        <div className="form-container">
          <form>
            <input
              className="username-input"
              type="text"
              name="username"
              htmlFor="username"
              placeholder="Username"
              onChange={handleLoginOnChange}
            />
            <label id="username" hidden>
              Username
            </label>

            <input
              className="password-input"
              type="password"
              name="password"
              htmlFor="password"
              placeholder="Password"
              onChange={handleLoginOnChange}
            />
            <label id="password" hidden>
              Password
            </label>

            <div className="remember-forgot">
              <div className="checkbox-input">
                <input className="check-box" type="checkbox" name="remember" />
                <label className="checkbox-label" htmlFor="remember">
                  Remember me
                </label>
              </div>
              <a className="forgot-password" href="/">
                Forgot password?
              </a>
            </div>

            <Button
              label="Submit"
              className="submit-button"
              color="white"
              onClick={loginOnSubmit}
              isFetching={isLoading}
              disabled={isLoading}
            />
          </form>
          <a className="eula" href="/">
            Eula & Privacy Policy
          </a>
          <div className="horizontal-br">
            <hr />
            Or
            <hr />
          </div>
          <Button
            className="oauth-buttons"
            label="Login with Apple"
            icon={<BsApple size={25} />}
          />
          <Button
            className="oauth-buttons"
            label="Login with Google"
            icon={<FcGoogle size={25} />}
          />
          <Button
            className="oauth-buttons"
            label="Login with Facebook"
            icon={<BsFacebook size={25} color="blue" />}
          />
          <a href="/sign-up" className="redirection">
            Create new account
          </a>
        </div>
      </div>
    </LoginPageStyles>
  );
};

export default LoginPage;
