import styled from "styled-components";

export default styled.div`
  background: #fafafa;
  .top-bar {
    background: #f1a73c;
    padding: 3rem 2rem;
    .analytics-bar {
      background: white;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
      border-radius: 20px;
      padding: 1rem;
      h4 {
        text-align: center;
        color: #8c8994;
        span {
          color: #f1a73c;
        }
      }
      .analytics {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        .charts {
          width: 48%;
          display: flex;
          .chart {
            width: 50%;
          }
          border-right: 1px solid #8c8994;
          padding-right: 1rem;
        }
        .analytic {
          width: 48%;
          display: flex;
          justify-content: space-between;
          .box {
            width: 48%;
            text-align: center;
            padding: 1rem 1rem;
            border-radius: 20px;
            &.referred {
              h4,
              h2 {
                color: #6cb132;
              }
              background: #6cb13222;
            }
            &.target {
              h4,
              h2 {
                color: #f1a73c;
              }
              background: #f1a73c22;
            }
          }
        }
      }
    }
  }
  .rewards-content {
    .rewards-content-items {
      margin: 2rem auto;
      width: 80%;
      .rewards-item {
        border-bottom: 1px solid #8C899477;
        display: flex;
        img {
          width: 3rem;
          margin-right: 1rem;
        }
        .content {
          line-height: 1;
          h3 {
            font-size: 1.1rem;
            line-height: 1.5;
            font-weight: 700;
          }
          h4 {
            line-height: 1.5;
            color: #8C8994;
            font-weight: 600;
          }
        }
        padding: 1rem;
        .earn-goodies {
          width: 80%;
          margin-right: 1.5rem;
          border: none;
          background: #6CB13233;
          padding: .8rem 1rem;
          font-size: 1rem;
          color: #6CB132;
          font-weight: 600;
          text-align: left;
          border-radius: 10px;
          cursor: pointer;
        }
        .claim {
          width: 15%;
          border: none;
          background: #6CB132;
          font-size: 1rem;
          font-weight: 600;
          color: white;
          border-radius: 10px;
          cursor: pointer;
        }
        &:last-child {
          border-bottom: none;
        }
      }
      background: #ffffff;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
      border-radius: 20px;
    }
  }
`;
