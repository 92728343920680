import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { URLs } from "../../constants/baseUrls";
import { setUser } from "../slices/userSlice";

const BASE_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PROD_URL
    : URLs.DEV_URL;

export const authApi = createApi({
  reducerPath: "auth",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/auth`,
  }),
  endpoints: (builder) => ({
    registerUser: builder.mutation({
      query: (data) => {
        return {
          url: "register",
          method: "POST",
          body: {},
        };
      },
    }),
    loginUser: builder.mutation({
      query: (data) => {
        return {
          url: 'signin',
          method: "POST",
          body: data,
        }
      },
      transformResponse: (result) => 
        result,
        async onQueryStarted(data, {dispatch, queryFulfilled}) {
          try {
            const data = await queryFulfilled;
            const { user } = data.data;
            console.log('ResponseTransform', data);
            localStorage.setItem('token', data.data.token);
            dispatch(setUser(user))
          } catch(error) {}
        }
    })
  }),
});

export const {
  useRegisterUserMutation,
  useLoginUserMutation
} = authApi;