import styled from 'styled-components';

export default styled.div`
	.sidebar-profile {
		.profile-details {
			display: flex;
			align-items: center;
			flex-direction: column;
			.profile-image {
				background-color: #f1a73c;
				width: 50%;
				height: 9rem;
				display: flex;
				justify-content: center;
				align-items: center;
				border-bottom-left-radius: 1rem;
				border-bottom-right-radius: 1rem;
				img {
					border-radius: 50%;
					width: 6rem;
					height: 6rem;
				}
			}
			.profile-info {
				text-align: center;
				p {
					color: #8c8994;
				}
				.profile-name {
					font-weight: bold;
				}
			}
		}
		button {
			display: flex;
			justify-content: center;
			align-items: center;
			border: none;
			padding: 0.8rem 1rem;
			background: #6cb132;
			border-radius: 0.4rem;
			margin-top: 1rem;
			svg {
				width: 1rem;
			}
			span {
				margin-left: 0.5rem;
				font-weight: bold;
			}
			color: white;
			&:hover {
				cursor: pointer;
			}
		}
	}
`;
