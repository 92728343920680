import styled from 'styled-components';

export default styled.div`
	width: 20%;
	box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
	height: 100vh;
	position: sticky;
	right: 0;
	top: 0;
	.sidebar-menu {
		margin: 1rem auto;
		button {
			justify-content: flex-start;
		}
	}
`;
