import styled from "styled-components";

export default styled.button`
  display: flex;
  align-items: center;
  border: none;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 0.8rem;
  margin: 0.5rem 1rem;
  cursor: pointer;
  .label {
    font-size: 1rem;
    margin-left: 1rem;
    color: #8c8994;
  }
  .left-icon {
    width: 1rem;
		color: #8C8994;
  }
  .right-icon {
    width: 1rem;
		color: #8C8994;
  }
  &.active {
    .label {
      color: #6cb132;
    }
    .left-icon {
			svg {
      	color: #6cb132;
			}
    }
  }
  &:disabled {
    background: #6cb13266;
  }
`;
