import styled from "styled-components";

export default styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 1rem;
  margin-bottom: 1rem;
  .dish-content {
    display: flex;
    justify-content: space-between;
    .image {
      width: 30%;
      height: 8rem;
      img {
      object-fit: cover;
      }
    }
    .content {
      width: 65%;
      .dish-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h3 {
          font-size: 1.2rem;
        }
        .three-dots {
          width: 1rem;
        }
      }
      .price-restaurant {
        display: flex;
        justify-content: space-between;
        margin-top: 0.5rem;
        font-weight: 600;
        .restaurant {
          & > span {
            color: #6cb132;
            cursor: pointer;
          }
        }
        .price {
          color: #6cb132;
        }
      }
      .location {
        display: flex;
        justify-content: space-between;
        .location,
        .entrees {
          display: flex;
          align-items: center;
          svg {
            width: 1.2rem;
            margin-right: 0.4rem;
          }
        }
      }
      .other-details {
        display: flex;
        justify-content: space-between;
        margin-top: 0.5rem;
        .posted-by,
        .see-more {
          font-size: 0.9rem;
          font-weight: 600;
          & > span {
            color: #6cb132;
          }
        }
        .see-more {
          & > span {
            display: flex;
            align-items: center;
            svg {
              width: 0.8rem;
              margin-left: 0.4rem;
            }
          }
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
  .info {
    transition: all 2s ease-out;
    .allergy {
      background: rgba(108, 177, 50, 0.2);
      padding: 0.3rem 1rem;
      margin-top: 0.5rem;
      border-radius: 10px;
      p {
        font-size: 0.8rem;
        color: #6cb132;
        & > span {
          font-weight: bold;
        }
      }
    }
    .hashtag {
      background: rgba(242, 153, 74, .2);
      padding: 0.3rem 1rem;
      margin-top: 0.5rem;
      border-radius: 5px;
      p {
        font-size: 0.8rem;
        color: #F2994A;;
        & > span {
          font-weight: bold;
        }
      }
    }
  }
  .reactions {
    display: flex;
    div {
      background: #F8FAFC;
      width: 33.33%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: .5rem 0;
      margin-top: 1rem;
      font-weight: 600;
      color: #8C8994;
      svg {
        width: 1.2rem;
        margin-right: .6rem;
      }
      &:nth-child(1) {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        color: #FF4C4C;
      }
      &:nth-child(3) {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
`;
