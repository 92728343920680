import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
  // Navigate
} from "react-router-dom";

import { GlobalStyles } from "./GlobalStyles";
import LoginPage from "./pages/LogInPage";
import SignUpPage from "./pages/SignUpPage";
import HomePage from "./pages/HomePage";
import ContributionPage from "./pages/ContributionPage";
import RewardsPage from "./pages/RewardsPage";
import MyRestaurantPage from "./pages/MyRestaurantPage";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";

function App() {
  const token = localStorage.getItem('token');

  const router = createBrowserRouter([
    {
      path: "/",
      element: token ? <HomePage /> : <Navigate to="/login" />,
    },
    {
      path: "/login",
      element: <LoginPage />,
    },
    {
      path: "/sign-up",
      element: <SignUpPage />,
    },
    {
      path: "/contribution",
      element: <ContributionPage />,
    },
    {
      path: "/rewards",
      element: <RewardsPage />,
    },
    {
      path: "/my-restaurants",
      element: <MyRestaurantPage />,
    },
  ]);

  return (
    <div className="App">
      <GlobalStyles />
      <ToastContainer />
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
