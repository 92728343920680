import { createGlobalStyle } from 'styled-components';

const size = {
	mobileS: '320px',
	mobileM: '375px',
	mobileL: '425px',
	tablet: '768px',
	laptop: '1024px',
	laptopL: '1440px',
	desktop: '2560px',
};

export const device = {
	mobileS: `(max-width: ${size.mobileS})`,
	mobileM: `(max-width: ${size.mobileM})`,
	mobileL: `(max-width: ${size.mobileL})`,
	tablet: `(max-width: ${size.tablet})`,
	laptop: `(max-width: ${size.laptop})`,
	laptopL: `(min-width: ${size.laptopL})`,
	desktop: `(min-width: ${size.desktop})`,
	desktopL: `(min-width: ${size.desktop})`,
};

export const GlobalStyles = createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  :root{
    --primaryButton: #FCBE43;
    --secondary: #753AFF;
    --title: #192936;
    --primaryText: #4D5967;
    --secondaryText: #4D5967;
    --navText: rgba(0, 0, 0, 0.6);
    --activeNavText: #006BAB;
    --darkBlue: #070747; 
    --darkPurple: #0E0034;
    --black-1: #0D121F;
    --black-2: #121826;
    --white-1: #C6BED9;
    --gray: #64718A;
    --grey: var(--gray);
    --white: white;
    --black: black;
  }
  html{
    font-size: 1rem;
  }

  body{
    min-height: 100vh;
    line-height: 1.5;
    width: 100%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: var(--title);
    background-color: white;
  }
  a{
    text-decoration: none;
    color: var(--gray);
  }
  .container{
    max-width: 1200px;
    margin: 0 auto;
    width: 90%;
  }
  img, svg{
    height: 100%;
    width: 100%;
  }
  li, ul{
    list-style: none;
    line-height: 1.1;
  }
  h1 {
    font-size: 5rem;
    font-weight: 500;
    color: var(--primaryText);
  }
  h2 {
    font-size: 3rem;
  }
  h3 {
    font-size: 1.8rem;
  }
  p {
    font-size: 1rem;
    color: var(--primaryText)
  }

  .App {
    display: flex;
    .main {
      display: flex;
      flex-grow: 1;
      .main-content {
        width: 60%;
        overflow-y: auto;
      }
    }
  }
  @media only screen and (${device.tablet}) {
    h1 {
      font-size: 3rem;
      font-weight: 500;
    }
    h2 {
      font-size: 2rem;
    }
    p {
      font-size: 1rem;
      color: var(--primaryText)
    }
  }
  @media only screen and (${device.mobileL}) {
    h1 {
      font-size: 2.5rem;
      font-weight: 500;
    }
    h2 {
      font-size: 2rem;
    }
    p {
      font-size: 1rem;
      color: var(--primaryText)
    }
  }
`;
