import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import userReducer from "./slices/userSlice";
import { setUser } from "./slices/userSlice";
import { authApi } from "./apis/authApi";
import { postApi } from "./apis/postApi";
import { userApi } from "./apis/userApi";

export const store = configureStore({
  reducer: {
    user: userReducer,
    [authApi.reducerPath]: authApi.reducer,
    [postApi.reducerPath]: postApi.reducer,
    [userApi.reducerPath]: userApi.reducer
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware()
      .concat(authApi.middleware)
      .concat(postApi.middleware)
      .concat(userApi.middleware)
  },
});

setupListeners(store.dispatch);

export { setUser };

export { useRegisterUserMutation, useLoginUserMutation } from "./apis/authApi";

export { useFetchPostsQuery, useFetchPostByIdQuery } from "./apis/postApi";

export { useFetchUserQuery } from './apis/userApi';
