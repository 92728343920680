const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  user: null
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: () => initialState,
    setUser: (state, action) => {
      console.log(action);
      state.user = {...action.payload}
    }
  }
})

export default userSlice.reducer;
export const { logout, setUser } = userSlice.actions;