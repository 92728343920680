import React from "react";
import ContributionPageStyles from "./styles";
import TopSearchComponent from "../../components/TopSearchComponent";
import Layout from "../../components/Layout";

const ContributionPage = () => {
  return (
    <Layout>
      <ContributionPageStyles>
        <TopSearchComponent />
      </ContributionPageStyles>
    </Layout>
  );
};

export default ContributionPage;
