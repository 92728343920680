import React, { useState } from "react";
import Layout from "../../components/Layout";
import MYRestaurantPageStyles from "./styles";
import RestaurantProfile from "./images/res-profile.svg";
import Button from "../../components/Button";

const threeDots = (
  <svg
    width="6"
    height="24"
    viewBox="0 0 6 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.99998 4.99997C4.38068 4.99997 5.49995 3.88069 5.49995 2.49998C5.49995 1.11928 4.38068 0 2.99998 0C1.61928 0 0.5 1.11928 0.5 2.49998C0.5 3.88069 1.61928 4.99997 2.99998 4.99997Z"
      fill="currentColor"
    />
    <path
      d="M2.99998 14.5C4.38068 14.5 5.49995 13.3807 5.49995 12C5.49995 10.6193 4.38068 9.5 2.99998 9.5C1.61928 9.5 0.5 10.6193 0.5 12C0.5 13.3807 1.61928 14.5 2.99998 14.5Z"
      fill="currentColor"
    />
    <path
      d="M2.99998 24C4.38068 24 5.49995 22.8807 5.49995 21.5C5.49995 20.1193 4.38068 19 2.99998 19C1.61928 19 0.5 20.1193 0.5 21.5C0.5 22.8807 1.61928 24 2.99998 24Z"
      fill="currentColor"
    />
  </svg>
);

const call = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5249 10.5357C15.024 10.0585 14.3586 9.79231 13.6667 9.79231C12.9747 9.79231 12.3094 10.0585 11.8084 10.5357L11.5011 10.8362C9.58051 9.96084 8.04288 8.41853 7.17366 6.49557L7.46597 6.19959C7.95014 5.70954 8.22407 5.05005 8.22954 4.36127C8.23501 3.67249 7.9716 3.00873 7.49527 2.51106L5.87747 0.769713C5.38967 0.282242 4.72985 0.00585302 4.04016 9.18942e-05C3.35048 -0.00566923 2.68613 0.259659 2.19025 0.738913C2.19025 0.738913 1.38924 1.43755 1.37046 1.45558C-4.56575 7.66673 10.3289 22.5651 16.5491 16.6304C16.5672 16.6116 17.266 15.8108 17.266 15.8108C17.7474 15.3083 18.011 14.6362 17.9996 13.9405C17.9882 13.2448 17.7027 12.5817 17.2051 12.0952L15.5249 10.5357ZM15.6429 14.2482C15.6241 14.2663 14.9298 15.0618 14.9298 15.0618C10.9826 18.6106 -0.71398 7.60664 2.93867 3.07974C2.93867 3.07974 3.73442 2.3856 3.75245 2.36682C3.81762 2.30069 3.90497 2.26102 3.99766 2.25547C4.09035 2.24992 4.18181 2.27888 4.2544 2.33677L5.87221 4.07436C5.90925 4.10891 5.93866 4.15081 5.95856 4.19738C5.97846 4.24395 5.98841 4.29416 5.98778 4.3448C5.98714 4.39544 5.97593 4.44538 5.95486 4.49144C5.9338 4.53749 5.90334 4.57864 5.86544 4.61224L5.03212 5.45812C4.87841 5.61446 4.77366 5.81222 4.73068 6.02718C4.6877 6.24214 4.70838 6.46496 4.79016 6.66835C5.35011 8.16698 6.22581 9.52773 7.35789 10.6584C8.48997 11.789 9.85196 12.6631 11.3516 13.2213C11.5534 13.2985 11.7733 13.316 11.9848 13.2718C12.1964 13.2276 12.3908 13.1235 12.5448 12.9719L13.3969 12.132C13.6148 11.9713 13.7283 11.9728 13.959 12.1621L15.6407 13.7201C15.7102 13.7904 15.7492 13.8853 15.7492 13.9842C15.7492 14.083 15.7102 14.1779 15.6407 14.2482H15.6429Z"
      fill="#8C8994"
    />
  </svg>
);

const location = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 0C7.21997 0 5.47991 0.527841 3.99987 1.51677C2.51983 2.50571 1.36628 3.91131 0.685088 5.55585C0.00389957 7.20038 -0.17433 9.00998 0.172937 10.7558C0.520204 12.5016 1.37737 14.1053 2.63604 15.364C3.89472 16.6226 5.49836 17.4798 7.24419 17.8271C8.99002 18.1743 10.7996 17.9961 12.4442 17.3149C14.0887 16.6337 15.4943 15.4802 16.4832 14.0001C17.4722 12.5201 18 10.78 18 9C17.9974 6.61384 17.0484 4.32616 15.3611 2.63889C13.6738 0.951621 11.3862 0.00258081 9 0V0ZM9.375 15.7313V14.7675C9.375 13.9341 9.04392 13.1348 8.45459 12.5454C7.86525 11.9561 7.06595 11.625 6.2325 11.625H6.225C5.91535 11.625 5.60873 11.564 5.32266 11.4455C5.03659 11.327 4.77667 11.1533 4.55775 10.9343L2.268 8.643C2.32917 7.45151 2.70556 6.29762 3.35872 5.29924C4.01188 4.30085 4.91839 3.49377 5.98561 2.96046C7.05284 2.42716 8.24252 2.18674 9.43308 2.26379C10.6237 2.34084 11.7724 2.73259 12.762 3.399C12.5634 3.54475 12.3236 3.62354 12.0773 3.624H11.0018C10.8484 3.624 10.6966 3.65424 10.5549 3.71299C10.4133 3.77174 10.2846 3.85785 10.1763 3.96638C10.068 4.07492 9.98211 4.20375 9.92363 4.3455C9.86516 4.48726 9.83521 4.63916 9.8355 4.7925V5.574C9.8355 5.88331 9.71263 6.17995 9.49392 6.39866C9.2752 6.61738 8.97856 6.74025 8.66925 6.74025C8.51585 6.73986 8.36387 6.76973 8.22203 6.82816C8.08019 6.8866 7.95127 6.97244 7.84265 7.08077C7.73404 7.18911 7.64786 7.31781 7.58907 7.4595C7.53027 7.60119 7.5 7.75309 7.5 7.9065V8.688C7.5 8.99731 7.62288 9.29395 7.84159 9.51266C8.06031 9.73138 8.35695 9.85425 8.66625 9.85425H11.7833C12.0926 9.85425 12.3892 9.97712 12.6079 10.1958C12.8266 10.4146 12.9495 10.7112 12.9495 11.0205V12.1605C12.9495 12.3137 12.9797 12.4655 13.0384 12.607C13.0971 12.7486 13.1831 12.8772 13.2915 12.9855L13.9163 13.6103C12.7325 14.8762 11.1056 15.636 9.375 15.7313Z"
      fill="#8C8994"
    />
  </svg>
);

const share = (
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.7501 14.9422C15.7501 13.1371 14.2391 11.6738 12.3751 11.6738H8.12411V12.894C8.12236 13.7821 7.3775 14.5007 6.46045 14.499C6.00152 14.4981 5.5634 14.3133 5.25013 13.9885L0.583592 9.12587C-0.194205 8.30808 -0.194205 7.04927 0.583592 6.23151L5.25009 1.36882C5.87383 0.71779 6.92448 0.679692 7.59674 1.28374C7.93561 1.58821 8.12805 2.01569 8.12784 2.46336V3.68429H10.8751C14.8082 3.68872 17.9956 6.77537 18.0001 10.5843V14.9422C18.0001 15.5439 17.4964 16.0317 16.8751 16.0317C16.2538 16.0317 15.7501 15.5439 15.7501 14.9422ZM15.7501 10.5843C15.7468 7.97828 13.5661 5.86644 10.8751 5.86324H6.99911C6.3778 5.86324 5.87411 5.37546 5.87411 4.77376V3.90218L2.23284 7.64347L5.87408 11.4407V10.5843C5.87408 9.98264 6.37776 9.49486 6.99908 9.49486H12.3751C13.5928 9.4934 14.7778 9.87665 15.7501 10.5865V10.5843Z"
      fill="#8C8994"
    />
  </svg>
);

const star = (
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.0739 16.3905C13.1458 16.9231 12.834 17.1627 12.4021 16.9231L8.77948 14.8992C8.34764 14.6595 7.62791 14.6595 7.19607 14.8992L3.54945 16.8965C3.11761 17.1361 2.80573 16.8965 2.8777 16.3639L3.50147 11.9166C3.57344 11.384 3.35752 10.6118 3.02165 10.239L0.190716 7.01674C-0.145157 6.61729 -0.0252027 6.24446 0.454616 6.13794L4.46111 5.41894C4.94092 5.33905 5.51671 4.85971 5.75662 4.38037L7.6519 0.385872C7.86782 -0.0934677 8.25168 -0.0934677 8.4676 0.385872L10.3389 4.38037C10.5548 4.85971 11.1306 5.33905 11.6104 5.41894L15.6169 6.19121C16.0967 6.2711 16.2167 6.67054 15.8568 7.06999L13.0019 10.2656C12.666 10.6384 12.4261 11.4107 12.4981 11.9433L13.0739 16.3905Z"
      fill="url(#paint0_radial_1206_26165)"
    />
    <defs>
      <radialGradient
        id="paint0_radial_1206_26165"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(2.71843 17.2284) scale(16.7966 18.6442)"
      >
        <stop offset="0.3548" stop-color="#FDC20F" />
        <stop offset="0.4977" stop-color="#FDBD17" />
        <stop offset="0.6991" stop-color="#FBB029" />
        <stop offset="0.9342" stop-color="#F89B3B" />
        <stop offset="1" stop-color="#F7943F" />
      </radialGradient>
    </defs>
  </svg>
);

const starArray = [];
const tags = ["All Dishes", "Curries", "Desserts", "Veg", "Non-Veg"];

for (let i = 0; i < 5; i++) {
  starArray.push(star);
}

const MyRestaurantPage = () => {
  const [filter, setFilter] = useState(0);

  const handleClick = (event) => {
    setFilter(event)
  };

  const filterBy = tags[filter];
  console.log(filterBy);
  return (
    <Layout>
      <MYRestaurantPageStyles>
        <div className="top-bar">
          <div className="restaurant">
            <div className="restaurant-profile">
              <img src={RestaurantProfile} alt="" />
              <div>
                <h2>Flesta Vallarta</h2>
                <h4>789 Seventh Avenue, New York, NY 10019</h4>
              </div>
            </div>
            {threeDots}
          </div>
          <div className="restaurant-details">
            <div className="row">
              <div>
                {call} <p>Call</p>
              </div>
              <div>
                {location} <p>Website</p>
              </div>
              <div>
                {share} <p>Share</p>
              </div>
            </div>
            <div className="row">
              <div className="rating">
                <h3>
                  4.5 <span>{starArray.map((element) => element)}</span>
                </h3>
                <p>Based on 490 ratings</p>
              </div>
              <p>See more</p>
            </div>
            <div className="row">
              <div className="first-row">
                <p>Opened Hours</p>
                <p className="link">Mon 9:00 AM to 10:00PM</p>
              </div>
              <Button label="Claim Ownership" color="white" />
            </div>
          </div>
        </div>
        <div className="tags">
          <div className="tag-group">
            {tags.map((element, index) => (
              <div
                onClick={() => handleClick(index)}
                key={index}
                className={`tag ${filter === index ? "active" : ""}`}
              >
                {element}
              </div>
            ))}
          </div>
        </div>
      </MYRestaurantPageStyles>
    </Layout>
  );
};

export default MyRestaurantPage;
