import styled from 'styled-components';

export default styled.div`
	background: #f1a73c;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 4rem;
	.profile-bar {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 90%;
		.profile-detail {
			display: flex;
			align-items: center;
			img {
				height: 6rem;
				width: 6rem;
			}
			.name {
				color: #ffffff;
				font-size: 1.5rem;
				font-weight: bold;
			}
		}
		.contribution {
			color: #ffffff;
			font-weight: medium;
		}
	}
	.dish-search {
		width: 90%;
		display: flex;
		.search-box {
			background: #ffffff;
			box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
			border-radius: 10px;
			outline: none;
			border: none;
			padding: 0rem 1rem;
			width: 100%;
			&::placeholder {
				font-size: 1rem;
				color: #8C8994;
			}
		}
		button {
			background: #6cb132;
			width: 20%;
			margin: 0;
			margin-left: -6rem;
		}
	}
`;
