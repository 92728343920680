import React, { useState } from "react";

import SingUpPageStyles from "./styles";
import Button from "../../components/Button";

import { BsFacebook } from "react-icons/bs";
import { FcGoogle } from "react-icons/fc";
import { BsApple } from "react-icons/bs";

const SignUpPage = () => {
  const [signUp, setSignUp] = useState("user");
  const restaurantSignUp = signUp === "restaurant";

  const handleChange = (event) => {
    setSignUp(event.target.value);
  };

  return (
    <SingUpPageStyles>
      <div className="login-container">
        <h3>Sign Up</h3>
        <div className="form-container">
          <form>
            <div className="radio-group">
              <div>
                <input
                  className="radio-input"
                  type="radio"
                  name="user"
                  htmlFor="user"
                  value="user"
                  checked={signUp === "user"}
                  onChange={(event) => handleChange(event)}
                />
                <label id="user">User</label>
              </div>
              <div>
                <input
                  className="radio-input"
                  type="radio"
                  name="restaurant"
                  htmlFor="restaurant"
                  value="restaurant"
                  checked={signUp === "restaurant"}
                  onChange={(event) => handleChange(event)}
                />
                <label id="restaurant">Restaurant Owner</label>
              </div>
            </div>

            <div className="username">
              <input
                className="username-input"
                type="text"
                name="username"
                htmlFor="username"
                placeholder="Username"
              />
              <label id="username" hidden>
                Username
              </label>
            </div>

            <div className="password">
              <input
                className="password-input"
                type="password"
                name="password"
                htmlFor="password"
                placeholder="Password"
              />
              <label id="password" hidden>
                Password
              </label>
            </div>

            {!restaurantSignUp && <div className="referral">
              <input
                className="referral-input"
                type="text"
                name="referral"
                htmlFor="referral"
                placeholder="Referral Code"
              />
              <label id="referral" hidden>
                Referral Code
              </label>
            </div>}

            {restaurantSignUp && <div className="email">
              <input
                className="email-input"
                type="email"
                name="email"
                htmlFor="email"
                placeholder="Email"
              />
              <label id="email" hidden>
                Email
              </label>
            </div>}

            {restaurantSignUp && <div className="restaurant-name">
              <input
                className="restaurant-input"
                type="text"
                name="restaurantName"
                htmlFor="restaurantName"
                placeholder="Restaurant Name"
              />
              <label id="restaurantName" hidden>
                Restaurant Name
              </label>
            </div>}

            <div className="city">
              <input
                className="city-input"
                type="text"
                name="city"
                htmlFor="city"
                placeholder="Preferred City"
              />
              <label id="city" hidden>
                Preferred City
              </label>
            </div>

            <Button
              label="Sign Up"
              className="submit-button"
              type="submit"
              color="white"
            />
          </form>
          <div className="horizontal-br">
            <hr />
            Or
            <hr />
          </div>
          {!restaurantSignUp && <Button
            className="oauth-buttons"
            label="Sign Up with Apple"
            icon={<BsApple size={25} />}
          />}
          {!restaurantSignUp && <Button
            className="oauth-buttons"
            label="Sign Up with Google"
            icon={<FcGoogle size={25} />}
          />}
          {!restaurantSignUp && <Button
            className="oauth-buttons"
            label="Sign Up with Facebook"
            icon={<BsFacebook size={25} color="blue" />}
          />}
          <a href="/login" className="redirection">
            Already have an account? <span>Login</span>
          </a>
        </div>
      </div>
    </SingUpPageStyles>
  );
};

export default SignUpPage;
