import React from 'react';
import LeftSidebar from '../LeftSidebar';
import RightSidebar from '../RightSidebar';

const Layout = ({children}) => {
  return (
    <div className='main'>
      <LeftSidebar />
        <div className='main-content'>
          {children}
        </div>
      <RightSidebar />
    </div>
  )
}

export default Layout