import styled from "styled-components";

export default styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  z-index: 9999999;
  display: flex;
  justify-content: center;
  background-color: #00000033;
  padding: 2rem;
  .inner-container {
    border-radius: 10px;
    background-color: white;
    width: 70%;
    padding: 1rem;
    & > h3 {
      margin-bottom: 1rem;
      margin-left: 1rem;
      margin-top: 1rem;
      color: #8c8994;
    }
    .columns {
      display: flex;
      justify-content: center;
      .inner-checkboxes {
        margin-top: 0.4rem;
        display: flex;
        flex-wrap: wrap;
        margin-left: 0.6rem;
        & > div {
          padding: 0.2rem 0.5rem;
          width: 32%;
          font-size: 0.9rem;
        }
      }
      .left-column {
        width: 48%;
        button {
          background: #8c8994;
          border-radius: 10px;
          color: white;
          width: 40%;
          width: 109px;
          height: 42px;
          font-size: 1rem;
          border: none;
          margin-top: 1rem;
          cursor: pointer;
        }
      }
      .right-column {
        width: 48%;
        button {
          background: #6cb132;
          border-radius: 10px;
          color: white;
          width: 40%;
          width: 109px;
          height: 42px;
          font-size: 1rem;
          border: none;
          margin-top: 1rem;
          cursor: pointer;
        }
      }
      input[type="checkbox"] {
        margin-right: 0.5rem;
      }
      input[type="email"],
      input[type="text"],
      select {
        width: 90%;
        border: 1px solid #8c8994;
        border-radius: 10px;
        font-size: 1rem;
        font-weight: 500;
        padding: 0.5rem 0.6rem;
        outline: none;
        margin: 0.5rem auto;
        color: #8c8994;
        &::placeholder {
          color: #8c8994;
          font-weight: 500;
        }
      }
      input[type="file"] {
        display: none;
      }
      .file-input {
        width: 100%;
        & > label {
          display: block;
          width: 90%;
          border: 1px solid #8c8994;
          border-radius: 10px;
          font-size: 1rem;
          padding: 0.3rem 0.6rem;
          outline: none;
          margin-top: 0.5rem;
          color: #8c8994;
          svg {
            width: 1rem;
          }
        }
      }
      .food-preference,
      .halal-meat,
      .vegan,
      .meat {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        color: #8c8994;
        font-weight: 500;
      }
    }
  }
`;
