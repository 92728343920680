import React from "react";
import Layout from "../../components/Layout/index";
import DonutChart from "../../components/DonutChart";
import RewardsPageStyles from "./styles";

import RewardsImage from "./images/rewards-img.svg";

const RewardsPage = () => {
  const options = {
    cutoutPercentage: 70,
  };

  const array = [0, 0, 0, 0];

  return (
    <Layout>
      <RewardsPageStyles>
        <div className="top-bar">
          <div className="analytics-bar">
            <h4>
              Total Goodies Earned: <span>460</span>
            </h4>
            <div className="analytics">
              <div className="charts">
                <div className="chart">
                  <DonutChart options={options} />
                </div>
                <div className="chart">
                  <DonutChart options={options} />
                </div>
              </div>
              <div className="analytic">
                <div className="box referred">
                  <h2>0</h2>
                  <h4>Referred Friends</h4>
                </div>
                <div className="box target">
                  <h2>1473</h2>
                  <h4>Goodies away from T-shirts</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rewards-content">
          <div className="rewards-content-items">
            {array.map((element, index) => (
              <div className="rewards-item" key={index}>
                <img src={RewardsImage} alt="" />
                <div className="content">
                  <h3>Get a Mug for 500 goodies</h3>
                  <h4>2 user have won so far</h4>
                </div>
              </div>
            ))}
            <div className="rewards-item">
              <button className="earn-goodies">How to earn goodies?</button>
              <button className="claim">Claim</button>
            </div>
          </div>
        </div>
      </RewardsPageStyles>
    </Layout>
  );
};

export default RewardsPage;
