import React from "react";
import ButtonStyles from "./styles";
import Spinner from "../Spinner";

const Button = ({
  label,
  icon,
  leftIcon,
  rightIcon,
  color,
  isFetching,
  ...rest
}) => {
  return (
    <ButtonStyles {...rest}>
      {isFetching ? (
        <Spinner />
      ) : (
        <>
          {leftIcon && <span className="left-icon">{leftIcon}</span>}
          <span
            className="label"
            style={{
              color: color && color,
            }}
          >
            {label}
          </span>
          {rightIcon && <span className="right-icon">{rightIcon}</span>}
        </>
      )}
    </ButtonStyles>
  );
};

export default Button;
