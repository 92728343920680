import styled from "styled-components";
import Background from "./images/background.svg";

export default styled.div`
  .top-bar {
    background-image: url(${Background});
    min-height: 4rem;
    display: flex;
    justify-content: center;
    .search-box-container {
      width: 90%;
      margin: 1rem auto;
      padding: 1rem 2rem;
      background: #ffffff;
      border-radius: 20px;
      .restaurant-search {
        background: #ffffff;
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        outline: none;
        border: none;
        padding: 1rem;
        width: 100%;
        &::placeholder {
          font-size: 1rem;
          color: #8C8994;
        }
      }
      .dish-search {
        display: flex;
        .search-box {
          background: #ffffff;
          box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          outline: none;
          border: none;
          padding: 1rem;
          width: 90%;
          &::placeholder {
            font-size: 1rem;
            color: #8C8994;
          }
        }
        button {
          background: #6cb132;
          width: 25%;
          margin: 0;
          margin-left: -6rem;
        }
      }
      .horizontal-br {
        margin: 1rem auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #BDBDBD;
        font-size: 1.1rem;
        hr {
          width: 45%;
          height: 1%;
          border: 1px solid #BDBDBD;
        }
      }
    }
  }
  .dish-lists {
    width: 90%;
    margin: 1rem auto;
    &>h3 {
      color: #8C8994;
      font-size: 1.3rem;
      margin-bottom: 2rem;
      margin-top: 2rem;
    }
  }
`;
