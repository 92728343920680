import React from 'react';
import TopSearchComponentStyles from './styles';
import ProfileImage from '../../images/sidebar/profile.svg';
import Button from '../Button';
import { BsChevronDown } from 'react-icons/bs';

const icon = (
	<svg
		width='14'
		height='14'
		viewBox='0 0 14 14'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<g clipPath='url(#clip0_956_11620)'>
			<path
				d='M12.2499 12.8037C12.2499 11.3539 11.0746 10.1787 9.62489 10.1787H6.31857V11.1587C6.3172 11.8719 5.73787 12.449 5.02461 12.4477C4.66766 12.447 4.3269 12.2986 4.08324 12.0377L0.453715 8.13233C-0.151238 7.47554 -0.151238 6.46453 0.453715 5.80776L4.08321 1.90233C4.56835 1.37947 5.38552 1.34887 5.90838 1.834C6.17195 2.07854 6.32163 2.42186 6.32146 2.78141V3.76198H8.45821C11.5173 3.76554 13.9964 6.24455 13.9999 9.30366V12.8037C13.9999 13.2869 13.6081 13.6787 13.1249 13.6787C12.6416 13.6787 12.2499 13.2869 12.2499 12.8037ZM12.2499 9.30366C12.2473 7.21066 10.5512 5.51455 8.45821 5.51198H5.44357C4.96032 5.51198 4.56857 5.12023 4.56857 4.63698V3.93698L1.73646 6.94176L4.56854 9.99144V9.30368C4.56854 8.82044 4.96029 8.42868 5.44354 8.42868H9.62489C10.572 8.42751 11.4936 8.73532 12.2499 9.30543V9.30366Z'
				fill='currentColor'
			/>
		</g>
		<defs>
			<clipPath id='clip0_956_11620'>
				<rect width='14' height='14' fill='white' />
			</clipPath>
		</defs>
	</svg>
);

const TopSearchComponent = () => {
	return (
		<TopSearchComponentStyles>
			<div className='profile-bar'>
				<div className='profile-detail'>
					<img src={ProfileImage} alt='profile' />
					<span className='name'>Emma Charolette</span>
				</div>
				<p className='contribution'>
					Total No of Contribution: 20
				</p>
				<Button
					label='Share'
					leftIcon={icon}
					style={{
						color: 'green',
						height: '2rem',
					}}
					color='#6CB132'
				/>
			</div>
			<div className="dish-search">
              <input
                type="text"
                className="search-box"
                placeholder="Search by Dish Name"
              />
              <Button
                label="Sunny vale"
                color="white"
                rightIcon={
                  <BsChevronDown
                    style={{ color: "white", fontWeight: "bold" }}
                  />
                }
              />
            </div>
		</TopSearchComponentStyles>
	);
};

export default TopSearchComponent;
