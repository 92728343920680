import React, { useState } from "react";
import Layout from "../../components/Layout";
import HomePageStyles from "./styles";
import Button from "../../components/Button";
import { BsChevronDown } from "react-icons/bs";
import { CardData } from "../../data/homePageData";
import DishCard from "../../components/DishCard";
import { useFetchPostsQuery } from "../../store";
import Spinner from "../../components/Spinner";
import Skeleton from "react-loading-skeleton";

const HomePage = () => {
  const [query, setQuery] = useState({
    page: 1,
    size: 10,
  });
  const { data, error, isFetching } = useFetchPostsQuery(query);
  return (
    <Layout>
      <HomePageStyles>
        <div className="top-bar">
          <div className="search-box-container">
            <input
              type="text"
              className="restaurant-search"
              placeholder="Search by Restaurant Name"
            />
            <div className="horizontal-br">
              <hr />
              OR
              <hr />
            </div>
            <div className="dish-search">
              <input
                type="text"
                className="search-box"
                placeholder="Search by Dish Name"
              />
              <Button
                label="Sunny vale"
                color="white"
                rightIcon={
                  <BsChevronDown
                    style={{ color: "white", fontWeight: "bold" }}
                  />
                }
              />
            </div>
          </div>
        </div>
        <div className="dish-lists">
          <h3>Recently Rated Dishes in Sunny vale</h3>
          {isFetching ? (
            <Skeleton width={100} count={5} height={20} />
          ) : (
            data?.data.map((element, index) => (
              <DishCard
                key={index}
                dishImage={element?.imageUrl}
                dishName={element?.dishName}
                restaurant={element?.restaurant?.name || "N/A"}
                cuisine={element?.cuisine?.name || "N/A"}
                price={element?.price}
                postedBy={element?.createdBy?.username || "N/A"}
                regretCount={element?.regretCount}
                loveCount={element?.loveCount}
                likeCount={element?.likeCount}
              />
            ))
          )}
        </div>
      </HomePageStyles>
    </Layout>
  );
};

export default HomePage;
