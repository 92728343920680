import styled from "styled-components";

export default styled.div`
  background: #fafafa;
  .top-bar {
    padding: 1rem 0;
    background-color: #f1a73c;
    .restaurant {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 80%;
      margin: 0rem auto;
      .restaurant-profile {
        display: flex;
        align-items: center;
        color: white;
        img {
          width: 4rem;
        }
        h2 {
          font-size: 1.5rem;
          line-height: 1.1;
          font-weight: 600;
        }
        h4 {
          font-weight: 600;
        }
      }
      svg {
        width: 0.4rem;
        color: white;
      }
    }
    .restaurant-details {
      width: 80%;
      margin: 1rem auto;
      background: white;
      border-radius: 10px;
      .row {
        display: flex;
        &:nth-child(1) {
          justify-content: space-between;
          div {
            padding: 0.5rem 1rem;
            display: flex;
            svg {
              width: 1rem;
              margin-right: 0.5rem;
            }
          }
          border-bottom: 1px solid #dddddd;
        }
        &:nth-child(2) {
          padding: 0.5rem 1rem;
          align-items: center;
          justify-content: space-between;
          font-weight: 600;
          .rating {
            & > h3 {
              font-size: 1.5rem;
              display: flex;
              align-items: center;
              line-height: 1;
              span {
                display: flex;
                margin-left: 0.5rem;
                svg {
                  height: 1rem;
                }
              }
            }
          }
          & > p {
            cursor: pointer;
            color: #6cb132;
          }
          border-bottom: 1px solid #dddddd;
        }
        &:nth-child(3) {
          flex-direction: column;
          .first-row {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 0.5rem 1rem;
            font-weight: 600;
            .link {
              cursor: pointer;
              color: #6cb132;
            }
          }
          button {
            text-align: center;
            background-color: #6cb132;
            justify-content: center;
          }
        }
      }
    }
  }
  .tags {
    background: white;
    .tag-group {
      display: flex;
      width: 80%;
      margin: 0rem auto;
      .tag {
        width: 84px;
        height: 39px;
        background: #f8fafc;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #bdbdbd;
        font-weight: 600;
        margin-right: 1rem;
        cursor: pointer;
        &.active {
          color: #6CB132;
        }
      }
    }
  }
`;
