import styled from "styled-components";
import Background from "./images/loginbg.svg";

export default styled.div`
  background-image: url(${Background});
  display: flex;
  justify-content: center;
  padding: 4rem 0;
  align-items: center;
  .login-container {
    background: #ffffff;
    border-radius: 10px;
    padding: 1rem;
    .form-container {
      width: 300px;
      form {
        display: flex;
        flex-direction: column;
        .username, .password, .referral, .city, .email, .restaurant-name {
          .username-input,
          .password-input,
          .referral-input,
          .email-input,
          .restaurant-input,
          .city-input {
            width: 100%;
            padding: 0.8rem 0.5rem;
            border: 1px solid #8c8994;
            border-radius: 10px;
            margin-top: 1rem;
          }
        }
        .remember-forgot {
          display: flex;
          justify-content: space-between;
          margin-top: 1rem;
          .checkbox-input {
            .check-box {
              margin-right: 0.5rem;
              accent-color: #6cb132;
            }
          }
          .forgot-password {
          }
        }
        .submit-button {
          background: #6cb132;
          text-align: center;
          margin-left: 0;
          margin-top: 1rem;
          width: 100%;
        }
      }
    }
    .eula {
      margin: 0 auto;
    }
    .horizontal-br {
      margin-top: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      hr {
        width: 45%;
        height: 1%;
      }
    }
    .oauth-buttons {
      width: 100%;
      margin-left: 0;
    }
    .redirection {
      display: flex;
      justify-content: center;
      color: #f1a73c;
      span {
        font-weight: bold;
        margin-left: 0.4rem;
      }
    }
  }
`;
