import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import EditProfileStyles from "./styles";

const EditProfileComponent = ({ onClose }) => {
  const pinIcon = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.0092 20C6.79209 20.0026 4.66222 19.2121 3.07699 17.7982C-0.240079 14.654 -0.175308 9.66304 3.22245 6.59133L8.55261 1.73018C10.7305 -0.313519 14.2269 -0.579408 16.7457 1.10717C19.4292 2.98638 19.9344 6.49402 17.8742 8.94174C17.7133 9.13292 17.539 9.31441 17.3524 9.48505L11.7746 14.5729C11.1469 15.1716 10.3138 15.5567 9.41332 15.6645C8.19276 15.7865 6.98446 15.3536 6.18118 14.5065C4.94966 13.0675 5.08132 11.0181 6.48864 9.72012L10.7408 5.84483C11.28 5.35312 12.1541 5.35312 12.6934 5.84483C13.2324 6.3367 13.2324 7.13401 12.6934 7.62587L8.31048 11.6246C7.92483 11.9766 7.92505 12.5472 8.31096 12.8989C8.69687 13.2506 9.32232 13.2505 9.70792 12.8985L15.3363 7.76529C16.4918 6.74043 16.7055 5.11861 15.8491 3.8732C14.8378 2.45224 12.7552 2.04811 11.1974 2.97059C11.0023 3.08608 10.8209 3.21971 10.6561 3.36937L5.10134 8.44043C3.00177 10.3 2.72901 13.2878 4.46247 15.4386C6.39056 17.7292 9.98927 18.1603 12.5003 16.4015C12.6966 16.2641 12.8828 16.1153 13.0579 15.9559L17.4417 11.958C17.9809 11.4663 18.855 11.4663 19.3943 11.958C19.9333 12.4499 19.9333 13.2472 19.3943 13.739L15.0105 17.7369C13.4198 19.1896 11.2601 20.0041 9.0092 20Z"
        fill="#8C8994"
      />
    </svg>
  );

  useEffect(() => {
    document.body.classList.add("overflow-hidden");

    return () => document.body.classList.remove("overflow-hidden");
  }, []);

  const [form, setForm] = useState({
    name: "",
    email: "",
    foodPreferance: [],
    halalMeat: [],
    eggiterian: false,
    meat: [],
    vegan: [],
    testFood: false,
    city: "",
    state: "",
    country: "",
  });

  const handleChange = (event) => {
    if (event.target.name === "name") {
      setForm({ ...form, name: event.target.value });
    } else if (event.target.name === "email") {
      setForm({ ...form, email: event.target.value });
    }
    if (event.target.value === "foodPreference") {
    }
  };

  return ReactDOM.createPortal(
    <EditProfileStyles>
      <div className="inner-container">
        <h3>Edit Profile</h3>
        <div className="columns">
          <div className="left-column">
            <input
              type="text"
              id="name"
              name="name"
              value={form.name}
              onChange={handleChange}
              placeholder="Enter Name"
            />
            <label htmlFor="name" hidden>
              Email
            </label>
            <div className="food-preference">
              <div className="outer-checkbox">
                <input
                  value="foodPreference"
                  type="checkbox"
                  onChange={handleChange}
                />
                <label>Food Preference</label>
              </div>
              <div className="inner-checkboxes">
                <div>
                  <input type="checkbox" id="fish" />
                  <label htmlFor="fish">Fish</label>
                </div>
                <div>
                  <input type="checkbox" id="crustaceans" />
                  <label htmlFor="crustaceans">Crustaceans</label>
                </div>
                <div>
                  <input type="checkbox" id="mollusca" />
                  <label htmlFor="mollusca">Mollusca</label>
                </div>
                <div>
                  <input type="checkbox" id="roe" />
                  <label htmlFor="roe">Roe</label>
                </div>
                <div>
                  <input type="checkbox" id="echinoderms" />
                  <label htmlFor="echinoderms">Echinoderms</label>
                </div>
                <div>
                  <input type="checkbox" id="medusozoa" />
                  <label htmlFor="medusozoa">Medusozoa</label>
                </div>
              </div>
            </div>
            <div className="halal-meat">
              <div className="outer-checkbox">
                <input
                  value="halalMeat"
                  type="checkbox"
                  onChange={handleChange}
                />
                <label>Halal Meat</label>
              </div>
              <div className="inner-checkboxes">
                <div>
                  <input type="checkbox" id="fish" />
                  <label htmlFor="fish">Fish</label>
                </div>
                <div>
                  <input type="checkbox" id="crustaceans" />
                  <label htmlFor="crustaceans">Crustaceans</label>
                </div>
                <div>
                  <input type="checkbox" id="mollusca" />
                  <label htmlFor="mollusca">Mollusca</label>
                </div>
                <div>
                  <input type="checkbox" id="roe" />
                  <label htmlFor="roe">Roe</label>
                </div>
              </div>
            </div>
            <div className="country">
              <label htmlFor="country" hidden>
                Country
              </label>
              <select name="country" id="country">
                <option value="">Select an option</option>
                <option value="usa">United States of America</option>
                <option value="uk">United Kingdom</option>
                <option value="india">India</option>
                <option value="parrot">Parrot</option>
                <option value="spider">Spider</option>
                <option value="goldfish">Goldfish</option>
              </select>
            </div>
            <div className="country">
              <label htmlFor="country" hidden>
                Country
              </label>
              <select name="country" id="country">
                <option value="">Select an option</option>
                <option value="usa">United States of America</option>
                <option value="uk">United Kingdom</option>
                <option value="india">India</option>
                <option value="parrot">Parrot</option>
                <option value="spider">Spider</option>
                <option value="goldfish">Goldfish</option>
              </select>
            </div>
            <button onClick={onClose} >Close</button>
          </div>
          <div className="right-column">
            <input
              type="email"
              id="email"
              value={form.email}
              name="email"
              onChange={handleChange}
              placeholder="Enter Email Address"
            />
            <label htmlFor="email" hidden>
              Email
            </label>
            <div className="meat">
              <div className="outer-checkbox">
                <input
                  value="meat"
                  type="checkbox"
                  onChange={handleChange}
                />
                <label>Meat</label>
              </div>
              <div className="inner-checkboxes">
                <div>
                  <input type="checkbox" id="chicken" />
                  <label htmlFor="chicken">Chicken</label>
                </div>
                <div>
                  <input type="checkbox" id="turkey" />
                  <label htmlFor="turkey">Turkey</label>
                </div>
                <div>
                  <input type="checkbox" id="pork" />
                  <label htmlFor="pork">Pork</label>
                </div>
                <div>
                  <input type="checkbox" id="steak" />
                  <label htmlFor="steak">Steak</label>
                </div>
                <div>
                  <input type="checkbox" id="lamb" />
                  <label htmlFor="lamb">Lamb</label>
                </div>
                <div>
                  <input type="checkbox" id="beef" />
                  <label htmlFor="beef">Beef</label>
                </div>
                <div>
                  <input type="checkbox" id="duck" />
                  <label htmlFor="duck">Duck</label>
                </div>
                <div>
                  <input type="checkbox" id="bacon" />
                  <label htmlFor="bacon">Bacon</label>
                </div>
                <div>
                  <input type="checkbox" id="ham" />
                  <label htmlFor="ham">Ham</label>
                </div>
              </div>
            </div>
            <div className="vegan">
              <div className="outer-checkbox">
                <input
                  value="vegan"
                  type="checkbox"
                  onChange={handleChange}
                />
                <label>Veg/Vegan</label>
              </div>
              <div className="inner-checkboxes">
                <div>
                  <input type="checkbox" id="vegeterian" />
                  <label htmlFor="vegeterian">Vegeterian</label>
                </div>
                <div>
                  <input type="checkbox" id="vegan" />
                  <label htmlFor="vegan">Vegan</label>
                </div>
              </div>
            </div>
            <div className="country">
              <label htmlFor="country" hidden>
                Country
              </label>
              <select name="country" id="country">
                <option value="">Select an option</option>
                <option value="usa">United States of America</option>
                <option value="uk">United Kingdom</option>
                <option value="india">India</option>
                <option value="parrot">Parrot</option>
                <option value="spider">Spider</option>
                <option value="goldfish">Goldfish</option>
              </select>
            </div>
            <div className="file-input">
              <input type="file" name="file" id="file" />
              <label htmlFor="file">{pinIcon} Upload Picture</label>
            </div>
            <button onClick={onClose}>Submit</button>
          </div>
        </div>
      </div>
    </EditProfileStyles>,
    document.body
  );
};

export default EditProfileComponent;
